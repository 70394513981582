<template>
    <div>
        <!--begin::Head-->
        <div class="kt-login__head">
      <span class="kt-login__signup-label">{{
        $t("AUTH.GENERAL.NO_ACCOUNT")
      }}</span
      >&nbsp;&nbsp;
            <router-link
                    class="kt-link kt-login__signup-link"
                    :to="{ name: 'register' }"
            >
                {{ $t("AUTH.GENERAL.SIGNUP_BUTTON") }}
            </router-link>
        </div>
        <!--end::Head-->

        <!--begin::Body-->
        <div class="kt-login__body">
            <!--begin::Signin-->
            <div class="kt-login__form">
                <div class="kt-login__title">
                    <h3>Ingresar al sistema</h3>
                </div>

                <!--begin::Form-->
                <b-form class="kt-form" @submit.stop.prevent="onSubmit">
                    <div
                            role="alert"
                            v-bind:class="{ show: errors }"
                            class="alert fade alert-danger"
                            v-if="errors"
                    >
                        <div class="alert-text" v-for="(error, i) in errors" :key="i">
                            {{ error }}
                        </div>
                    </div>

                    <b-form-group
                            id="example-input-group-1"
                            label=""
                            label-for="example-input-1"
                    >
                        <b-form-input
                                id="example-input-1"
                                name="example-input-1"
                                v-model="$v.form.email.$model"
                                :state="validateState('email')"
                                aria-describedby="input-1-live-feedback"
                                placeholder="Correo electrónico"
                        ></b-form-input>

                        <b-form-invalid-feedback id="input-1-live-feedback">
                            La dirección email no es válida
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                            id="example-input-group-2"
                            label=""
                            label-for="example-input-2"
                    >
                        <b-form-input
                                type="password"
                                id="example-input-2"
                                name="example-input-2"
                                v-model="$v.form.password.$model"
                                :state="validateState('password')"
                                aria-describedby="input-2-live-feedback"
                                placeholder="Clave"

                        ></b-form-input>

                        <b-form-invalid-feedback id="input-2-live-feedback">
                            El password es requerido.
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <!--begin::Action-->
                    <div class="kt-login__actions">
                        <a href="#" class="kt-link kt-login__link-forgot" @click="$router.push({name:'reset' })">
                            {{ $t("AUTH.FORGOT.TITLE") }}
                        </a>
                        <b-button
                                type="submit"
                                id="kt_submit"
                                class="btn btn-primary btn-elevate kt-login__btn-primary" :class="btnLoadingClass"
                        >
                            {{ $t("AUTH.LOGIN.BUTTON") }}
                        </b-button>
                    </div>
                    <!--end::Action-->
                </b-form>
                <!--end::Form-->
            </div>
            <!--end::Signin-->
        </div>
        <!--end::Body-->
    </div>
</template>

<style lang="scss" scoped>
    .kt-spinner.kt-spinner--right:before {
        right: 8px;
    }
</style>

<script>
    import {mapState} from "vuex";
    import {LOGIN} from "@/store/auth.module";

    import {validationMixin} from "vuelidate";
    import {email, minLength, required} from "vuelidate/lib/validators";

    export default {
        mixins: [validationMixin],
        name: "login",
        data() {
            return {
                reset: false,
                form: {
                    email: "",
                    password: ""
                }
            };
        },
        validations: {
            form: {
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    minLength: minLength(3)
                }
            }
        },
        methods: {
            validateState(name) {
                const {$dirty, $error} = this.$v.form[name];
                return $dirty ? !$error : null;
            },
            resetForm() {
                this.form = {
                    email: null,
                    password: null
                };

                this.$nextTick(() => {
                    this.$v.$reset();
                });
            },
            onSubmit() {
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }

                const email = this.$v.form.email.$model;
                const password = this.$v.form.password.$model;

                // this.$store.dispatch(LOGOUT);

                const submitButton = document.getElementById("kt_submit");
                submitButton.classList.add(

                );
                this.$store.dispatch(LOGIN, {email, password}).then(() => {
                    this.$router.push('home')

                });
            }
        },
        computed: {
            ...mapState({
                errors: state => state.auth.errors,
                loading: state => state.auth.loading
            }),
            backgroundImage() {
                return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
            },

            btnLoadingClass() {
                if (this.loading) {
                    return "kt-spinner kt-spinner--light kt-spinner--right"
                }
                return '';
            }

        }
    };
</script>
